import { formatRelative, parseISO } from "date-fns";
import { de, enUS, es, fr } from "date-fns/locale";

export const getDateFnsLocaleForLang = (lang: string) => {
  let locale: Locale;
  switch (lang) {
    case "de":
      locale = de;
      break;
    case "es":
      locale = es;
      break;
    case "fr":
      locale = fr;
      break;
    default:
      locale = enUS;
  }

  return locale;
};

export const getDateRelativeToNow = (lang: string, date: Date) => {
  const locale = getDateFnsLocaleForLang(lang);
  return formatRelative(date, new Date(), { locale });
};

export const parseTimestamp = (date: string) => {
  return parseISO(date + "Z");
};

export const secondsToHours = (seconds: number) => {
  return seconds / 60 / 60;
};
